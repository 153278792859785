import React from 'react';
import Stack from "@mui/material/Stack";
import {
    Box,
    Typography
} from "@mui/material";
import {useParams, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addAnswer} from "../../store/quizSlice";
import {Transitions} from "../../helpers/transitions";
import {validateNumber} from "../../helpers/validation";
import {
    QuestionTypeSelect, QuestionTypeInput, QuestionTypeBmiIndex, QuestionTypeChips, OverallQuestionsCount
} from "../../data/quiz";
import {NumberInputQuestion} from "./components/numberInputQuestion";
import {QuizHeader} from "./components/header";
import {SelectQuestion} from "./components/selectQuestion";
import {BmiIndexQuestion} from "./components/bmiIndexQuestion";
import {ChipsQuestion} from "./components/chipsQuestion";
import {sendEndedQuiz, sendShownQuestion} from "../../helpers/analytics";

const Question = ({question, questionNum, answer, onNavigateNext}) => {
    const dispatch = useDispatch();

    const handleOnNext = (answer) => {
        dispatch(addAnswer({
            questionNum,
            answer: answer
        }));

        onNavigateNext();
    };

    switch (question.type) {
        case QuestionTypeSelect:
            return <SelectQuestion key={questionNum}
                                   options={question.options}
                                   initialAnswer={answer || []}
                                   isMultiSelect={question.isMultiSelect}
                                   onNextClick={handleOnNext}/>;
        case QuestionTypeInput:
            return <NumberInputQuestion key={questionNum}
                                        autoFocus={true}
                                        suffix={question.suffix}
                                        initialValue={answer || ''}
                                        validationFunc={(value) => validateNumber(Number(value), question.validation)}
                                        onNextClick={handleOnNext}/>
        case QuestionTypeBmiIndex:
            return <BmiIndexQuestion key={questionNum} onNextClick={handleOnNext}/>
        case QuestionTypeChips:
            return <ChipsQuestion key={questionNum}
                                  chips={question.chips}
                                  initialAnswer={answer || []}
                                  onNextClick={handleOnNext}/>
        default:
            throw new DOMException(`undefined question type ${question.type}`);
    }
};

const QuizPage = () => {
    const state = useSelector(s => s.quiz);
    let {questionNum} = useParams();
    questionNum = Number(questionNum);
    const navigate = useNavigate();
    const question = state.questions[questionNum];
    const answer = state.answers[questionNum];

    const handleNextQuestion = () => {
        const questionNumber = questionNum + 1;
        if (questionNumber > OverallQuestionsCount) {
            navigate('/loading-plan');
            sendEndedQuiz();
            return;
        }

        navigate('/quiz/' + questionNumber);
        sendShownQuestion(questionNumber);
    };

    const handlePrevQuestion = () => {
        const questionNumber = questionNum - 1;
        if (questionNumber < 1) {
            navigate('/feature-select');
            return;
        }

        navigate('/quiz/' + questionNumber);
    };

    return (
        <Transitions>
            <Stack direction="column" sx={{height: '100vh'}}>
                <Stack direction="column" mt={1}>
                    <QuizHeader questionNum={questionNum} questionCount={OverallQuestionsCount}
                                onBackClick={() => handlePrevQuestion()}/>
                    <Typography variant="h4" component="div" mt={2}>
                        {question.text}
                    </Typography>

                    <Box mt={5}>
                        <Question question={question}
                                  questionNum={questionNum}
                                  answer={answer}
                                  onNavigateNext={handleNextQuestion}/>
                    </Box>
                </Stack>
            </Stack>
        </Transitions>
    );
};

export default QuizPage;