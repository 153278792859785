import Stack from "@mui/material/Stack";
import {Box, Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {Transitions} from "../helpers/transitions";

function IntroPage() {
    return (
        <Transitions className="transition-h100">
            <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
                <Typography variant="h4">
                    500+ пользователей выбрали <span className="App-brandSecondaryColor">CookHaus</span>
                </Typography>

                <Box
                    id="image"
                    sx={{
                        mt: 3,
                        alignSelf: 'center',
                        flex: "1",
                        width: '100%',
                        backgroundImage: 'url("/assets/first-screen-img.jpg")',
                        backgroundSize: 'cover',
                        borderRadius: 4
                    }}
                />

                <Typography variant="body1" textAlign="center" component="p" mt={3}>
                    Пройдите короткий опрос, который поможет определить ваши цели, предпочтения и ограничения в питании.
                    Опрос в среднем занимает 2-3 минуты.
                </Typography>

                <Button variant="contained"
                        color="secondary"
                        sx={{
                            color: 'white',
                            mt: 3,
                            mb: 1,
                            textTransform: 'none',
                            borderRadius: 4
                        }}
                        size="large"
                        component={Link}
                        to="/feature-select"
                        fullWidth={true}>
                    Начать
                </Button>
            </Stack>
        </Transitions>
    );
}

export default IntroPage;