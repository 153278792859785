import Grid2 from "@mui/material/Unstable_Grid2";
import {Avatar, Button, Chip, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";

const QuestionChip = ({icon, text, name, isActive, onClick}) => {
    const textColor = isActive ? 'white' : 'primary';
    const chipColor = isActive ? 'secondary' : 'default';

    return (
        <Grid2 xs="auto">
            <Chip variant={isActive ? 'filled' : 'outlined'}
                  label={<Typography fontSize="16px" color={textColor}>{text}</Typography>}
                  avatar={<Avatar src={icon}/>}
                  color={chipColor}
                  onClick={() => onClick(name)}
                  clickable/>
        </Grid2>
    );
};

const NextButton = ({onClick}) => {
    return (
        <Paper elevation={1}
               sx={{
                   position: 'fixed',
                   bottom: 0,
                   zIndex: 999,
                   right: 0,
                   width: '100%'
               }}>
            <Button variant="contained"
                    color="secondary"
                    sx={{
                        color: 'white',
                        mb: 2,
                        mt: 2,
                        minWidth: '328px',
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={onClick}
                    size="large">
                Далее
            </Button>
        </Paper>
    );
};

export const ChipsQuestion = ({chips, initialAnswer, onNextClick}) => {
    const [answer, setAnswer] = useState(initialAnswer || []);

    useEffect(() => {
        setAnswer(initialAnswer);
    }, [initialAnswer]);

    const handleChipClick = (name) => {
        const newAnswer = answer
            ? answer.some(a => a === name)
                ? answer.filter(a => a !== name)
                : [...answer, name]
            : [name];

        setAnswer(newAnswer);
    };

    const handleOnNext = () => {
        onNextClick(answer);
        setAnswer([]);
    };

    return (
        <>
            <Grid2 container spacing={1} justifyContent="center">
                {chips.map(c => (
                    <QuestionChip {...c}
                                  key={c.name}
                                  isActive={answer && answer.some(a => a === c.name)}
                                  onClick={handleChipClick}
                    />))}
            </Grid2>
            <NextButton onClick={handleOnNext}/>
        </>
    );
};