export const [bmiMin, bmiMax] = [12, 31.5];

export const calcCaloriesAndBmi = ({weight, height, age, sex, activityLevel, goal}) => {
    const genderAdditionalValue = sex === 'male' ? 5 : -161;
    const dayActivityLevelCoefficients = {
        'verylow': 1.2,
        'low': 1.375,
        'mid': 1.55,
        'high': 1.725,
        'veryhigh': 1.9
    };

    const goalCoefficients = {
        'drop': 0.8,
        'maintain': 1,
        'gain': 1.2
    };

    const kcal = ((10 * weight) + (6.25 * height) - (5 * age) + genderAdditionalValue) * dayActivityLevelCoefficients[activityLevel] * goalCoefficients[goal];
    const proteins = 0.15 * kcal / 4;
    const fats = 0.3 * kcal / 9;
    const carbs = 0.55 * kcal / 4;
    const heightM = height / 100;
    let bmi = weight / (heightM * heightM);
    if (bmi > bmiMax) {
        bmi = bmiMax;
    }

    if (bmi < bmiMin) {
        bmi = bmiMin;
    }

    return {
        kcal,
        bmi,
        proteins,
        fats,
        carbs
    };
};

export const getBmiPhrase = (bmi) => {
    if (bmi < 18.5) {
        return {
            title: `Ваш ИМТ ${bmi} - ниже нормы`,
            type: 'warning',
            description: 'Нормальным ИМТ считается показатель от 18,5 до 24,9. Приложение учитывает показатель ИМТ для составления меню.'
        }
    }

    if (bmi < 24.5) {
        return {
            title: `Ваш ИМТ ${bmi} - в норме`,
            type: 'info',
            description: 'Ваш ИМТ считается нормальным. Приложение поможет поддерживать ваш показатель на нормальном уровне.'
        }
    }

    return {
        title: `Ваш ИМТ ${bmi} - избыточный вес`,
        type: 'warning',
        description: 'Нормальным ИМТ считается показатель от 18,5 до 24,9. Приложение учитывает показатель ИМТ для составления меню.'
    }
};