import {createSlice} from "@reduxjs/toolkit";
import {questionsData} from "../data/quiz";

export const quizSlice = createSlice({
    name: 'quiz',
    initialState: {
        questions: questionsData,
        answers: {}
    },
    reducers: {
        addAnswer: (state, action) => {
            const {questionNum, answer} = action.payload;

            return {
                ...state,
                answers: {
                    ...state.answers,
                    [questionNum]: answer
                }
            };
        },
    },
});

export const {addAnswer} = quizSlice.actions;
export default quizSlice.reducer;