import {useEffect, useState} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Item from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {sendVisitedPaywall} from "../helpers/analytics";
import {isAndroidOS} from "../helpers/isAndroid";

const CircularProgressFilled = ({onFinishProgress}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const duration = 6000;
        const intervalTime = 100;
        const totalSteps = duration / intervalTime;
        const step = 100 / totalSteps;

        let currentProgress = 0;
        const interval = setInterval(() => {
            currentProgress += step;
            setProgress(Math.min(currentProgress, 100));

            if (currentProgress >= 100) {
                onFinishProgress();
                clearInterval(interval);
            }
        }, intervalTime);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{
            position: 'relative'
        }}>
            <CircularProgress color="secondary" variant="determinate" value={progress} size="150px"/>
            <Typography variant="h6" fontSize="26pt"
                        sx={{
                            position: 'absolute',
                            bottom: '33%',
                            left: '25%'
                        }}>
                {`${Math.round(progress)}%`}
            </Typography>
        </Box>
    );
};

const LoadingPlanPage = () => {
    const navigate = useNavigate();
    const handleOnFinishProgress = () => {
        setTimeout(() => navigate('/order-plan'), 500);
        const isAndroid = isAndroidOS();
        sendVisitedPaywall(isAndroid);
    };

    return (
        <Stack direction="column" ml={1} mr={1} height="100vh">
            <Item className="QuizHeader-brandLogo" mt={2} href="https://cookhaus.guru" component="a">CookHaus</Item>

            <Stack direction="column"
                   alignItems="center"
                   spacing={2}
                   sx={{
                       margin: 'auto'
                   }}>
                <Typography variant="h4">
                    Создаем ваше меню
                </Typography>

                <CircularProgressFilled onFinishProgress={handleOnFinishProgress}/>
            </Stack>
        </Stack>
    );
};

export default LoadingPlanPage;