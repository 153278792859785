import {Button, ButtonBase, Grow, Icon, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";

const Option = ({isActive, text, subtext, name, icon, onClick}) => {
    const bgColor = isActive ? '#70B9BE' : '#E6EBF2';
    const textColor = isActive ? '#FFFFFF' : 'primary';

    return (
        <ButtonBase onClick={() => onClick(name)}>
            <Paper elevation={0}
                   sx={{
                       bgcolor: bgColor,
                       width: '100%'
                   }}
            >
                <Stack direction="row" alignItems="center" spacing={0}>
                    {icon && <Icon sx={{fontSize: "64px", color: textColor}}>{icon}</Icon>}
                    <Stack direction="column" spacing={0} p={2}>
                        <Typography variant="h6" color={textColor} component="p" align="left">
                            {text}
                        </Typography>
                        <Typography variant="body2" color={textColor} component="p" align="left">
                            {subtext}
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        </ButtonBase>
    );
};

const NextButton = ({availableNext, onClick}) => {
    return (
        <Grow in={availableNext}>
            <Paper elevation={1}
                   sx={{
                       position: 'fixed',
                       bottom: 0,
                       zIndex: 999,
                       right: 0,
                       width: '100%'
                   }}>
                <Button variant="contained"
                        color="secondary"
                        sx={{
                            color: 'white',
                            mb: 2,
                            mt: 2,
                            minWidth: '328px',
                            textTransform: 'none',
                            borderRadius: 4
                        }}
                        onClick={onClick}
                        size="large">
                    Далее
                </Button>
            </Paper>
        </Grow>
    );
};

export const SelectQuestion = ({options, initialAnswer, isMultiSelect, onNextClick}) => {
    const [answer, setAnswer] = useState(initialAnswer || []);
    const availableNextButton = isMultiSelect && answer && answer.length > 0;

    useEffect(() => {
        setAnswer(initialAnswer);
    }, [initialAnswer]);

    const handleOptionClick = (name) => {
        if (isMultiSelect) {
            const newAnswer = answer
                ? answer.some(a => a === name)
                    ? answer.filter(a => a !== name)
                    : [...answer, name]
                : [name];

            setAnswer(newAnswer);
        } else {
            const newAnswer = [name];
            setAnswer(newAnswer);
            setTimeout(() => onNextClick(newAnswer), 600);
        }
    };

    const handleOnNext = () => {
        onNextClick(answer);
        setAnswer([]);
    };

    return (
        <Stack direction="column" spacing={2}>
            {options.map((item) =>
                <Option {...item}
                        key={item.name}
                        isActive={answer.some(a => a === item.name)}
                        onClick={handleOptionClick}
                />)}

            <div className="App-footerSpace"></div>
            <NextButton availableNext={availableNextButton} onClick={handleOnNext}/>
        </Stack>
    );
};