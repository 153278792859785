import {motion} from "framer-motion";

const animationConfiguration = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0, transition: {duration: 0}},
};

const Transitions = ({key, children, className = undefined}) => {
    return (
        <motion.div
            key={key}
            className={className ? className : ''}
            variants={animationConfiguration}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{duration: .4}}
        >
            {children}
        </motion.div>
    );
};

export {Transitions};
