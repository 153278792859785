export const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#042628',
        },
        secondary: {
            main: '#70b9be',
        },
    },
};
