/* eslint-disable no-undef */

const YmTrackerId = 97935481;
const VkTrackerId = 3540779;
export const AbMetrikaId = `metrika.${YmTrackerId}`;

export const sendStartedQuiz = () => {
    ym(YmTrackerId, 'reachGoal', 'startedQuiz');
    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'startedQuiz'});
};

export const sendShownQuestion = (questionNum) => {
    ym(YmTrackerId, "params", {
        "showQuestion": questionNum
    });
};

export const sendEndedQuiz = () => {
    ym(YmTrackerId, 'reachGoal', 'endedQuiz');
    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'endedQuiz'});
};

export const sendVisitedPaywall = (isAndroid) => {
    if (!isAndroid) {
        return;
    }

    ym(YmTrackerId, 'reachGoal', 'visitedPaywall');
    ym(YmTrackerId, 'params', {
        'visitPaywallFromAndroid': isAndroid
    });

    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'visitedPaywall'});
};

export const sendStartPayment = () => {
    ym(YmTrackerId, 'reachGoal', 'startPayment');
    _tmr.push({type: 'reachGoal', id: VkTrackerId, goal: 'startPayment'});
}

export const sendDownloadApp = () => {
    ym(YmTrackerId, 'reachGoal', 'downloadApp');
}

export const sendRedirectPage = (to, from) => {
    ym(YmTrackerId, 'params', {
        'redirectPage': {
            'to': to,
            'from': from || ''
        }
    });
};

export const sendSelectedFeatures = (selectedFeatures) => {
    ym(YmTrackerId, 'params', {
        'selectedFeatures': selectedFeatures
    });
}

export const sendSuccessfulPayment = (amount) => {
    ym(YmTrackerId, 'reachGoal', 'successfulPayment', {
        order_price: amount,
        currency: "RUB"
    });
};

/* eslint-enable no-undef */