import React from 'react';
import Stack from "@mui/material/Stack";
import {Box, Button, ButtonBase, Grow, Paper, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Transitions} from "../helpers/transitions";
import {useNavigate} from "react-router-dom";
import {toggleFeature} from "../store/featuresSlice";
import {useDispatch, useSelector} from "react-redux";
import Item from "@mui/material/Stack";
import {sendSelectedFeatures, sendStartedQuiz} from "../helpers/analytics";

const Feature = ({imageName, text, isActive, onClick}) => {
    const bgColor = isActive ? '#70B9BE' : 'white';
    const textColor = isActive ? 'white' : 'primary';

    return (
        <ButtonBase onClick={onClick} sx={{height: '100%', width: '100%'}}>
            <Paper elevation={6} sx={{bgcolor: bgColor, height: '100%', width: '100%', borderRadius: 3}}>
                <Stack direction="column" spacing={1} p={2}>
                    <Box
                        sx={{
                            alignSelf: 'center',
                            height: '64px',
                            width: '64px',
                            backgroundImage: `url("/assets/${imageName}")`,
                            backgroundSize: 'cover'
                        }}
                    />
                    <Typography variant="body1" fontWeight="bold" color={textColor}>
                        {text}
                    </Typography>
                </Stack>
            </Paper>
        </ButtonBase>
    );
};

const FeatureSelectPage = () => {
    const features = useSelector(state => state.features);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFeatureClick = (feature) => () => {
        dispatch(toggleFeature(feature));
    };

    const handleStartQuiz = () => {
        sendStartedQuiz();
        sendSelectedFeatures(features.filter(f => f.isActive).map(f => f.text));
        navigate('/quiz/1');
    };

    return (
        <Transitions>
            <Stack direction="column" alignItems="center">
                <Item className="QuizHeader-brandLogo" href="https://cookhaus.guru" component="a" mt={2}>CookHaus</Item>
                <Stack direction="column" spacing={{xs: 2, md: 4}} mt={2}>
                    <Typography variant="h4" textAlign="center">
                        Что для вас <span className="App-brandSecondaryColor">наиболее</span> важно в приложении?
                    </Typography>
                    <Grid2 container rowSpacing={4} columnSpacing={2}>
                        {features.map((f) => (
                            <Grid2 xs={6} key={f.order}>
                                <Feature imageName={f.imageName} text={f.text} isActive={f.isActive}
                                         onClick={handleFeatureClick(f)}/>
                            </Grid2>
                        ))}
                    </Grid2>
                </Stack>
                <Grow in={features.some(f => f.isActive)}>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                            color: 'white',
                            mb: 2,
                            textTransform: 'none',
                            borderRadius: 4,
                            position: 'fixed',
                            bottom: 0,
                            minWidth: '328px'
                        }}
                        onClick={handleStartQuiz}
                        size="large"
                    >
                        Далее
                    </Button>
                </Grow>
            </Stack>
        </Transitions>
    );
};

export default FeatureSelectPage;