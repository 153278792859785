function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };

}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);


    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        "L", x, y,
        "Z"
    ].join(" ");

    return d;

}

function createSegment(svg, x, y, radius, startAngle, endAngle, color) {
    var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("d", describeArc(x, y, radius, startAngle, endAngle));
    path.setAttribute("fill", color);
    svg.appendChild(path);
}

function CreateCircle(id) {
    var svg = document.getElementById(id);
    var centerX = 50;
    var centerY = 50;
    var radius = 45;

    createSegment(svg, centerX, centerY, radius, 0, 54, '#F8E71C'); // Желтый сегмент
    createSegment(svg, centerX, centerY, radius, 54, 162, '#BD10E0'); // Фиолетовый сегмент
    createSegment(svg, centerX, centerY, radius, 162, 360, '#FF0080'); // Красный сегмент

    var innerCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    innerCircle.setAttribute("cx", centerX);
    innerCircle.setAttribute("cy", centerY);
    innerCircle.setAttribute("r", 20);
    innerCircle.setAttribute("fill", "#FFFFFF");
    svg.appendChild(innerCircle);
}

export {CreateCircle};