import {useLocation, useParams} from "react-router-dom";
import {sendDownloadApp, sendRedirectPage} from "../helpers/analytics";
import {isAndroidOS} from "../helpers/isAndroid";
import Stack from "@mui/material/Stack";
import {Block} from "@mui/icons-material";
import {useEffect} from "react";

const RedirectPage = () => {
    const location = useLocation();
    const {to} = useParams();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const isAndroid = isAndroidOS();
        const from = queryParams.get("from");

        if (to) {
            sendRedirectPage(to, from);
        }

        let newLocation = '/';
        switch (to) {
            case 'cook-assistant-land':
                newLocation = `/?fromAndroid=${isAndroid}`;
                break;
            case 'download-app':
                newLocation = 'https://redirect.appmetrica.yandex.com/serve/1037742030173800407';
                sendDownloadApp();
                break;
            default:
                break;
        }

        window.location.replace(newLocation);
    }, [location, to]);

    return (
        <Stack direction="column" alignItems="center" width="100%">
            <Block component="div" className="dots-flashing"/>
        </Stack>
    );
};

export default RedirectPage;