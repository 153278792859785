import {createSlice} from "@reduxjs/toolkit";
import {featuresData} from "../data/quiz";

const featuresSlice = createSlice({
    name: 'features',
    initialState: featuresData,
    reducers: {
        toggleFeature(state, action) {
            const feature = action.payload;
            return [
                ...state.filter(f => f.order !== feature.order),
                {
                    ...feature,
                    isActive: !feature.isActive
                }
            ].sort((a, b) => a.order - b.order);
        }
    }
});

export const {toggleFeature} = featuresSlice.actions;
export default featuresSlice.reducer;
