export const validateNumber = (number, {from, to}) => {
    if (!Number.isInteger(number)) {
        return 'Введите целое число';
    }

    if (number > to || number < from) {
        return `Введите число от ${from} до ${to}`;
    }

    return null;
};