import {Button, Grow, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";

const NextButton = ({availableNext, onClick}) => {
    return (
        <Grow in={availableNext}>
            <Button variant="contained"
                    color="secondary"
                    sx={{
                        color: 'white',
                        mb: 2,
                        mt: 4,
                        minWidth: '328px',
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={onClick}
                    size="large">
                Далее
            </Button>
        </Grow>
    );
};

export const NumberInputQuestion = ({suffix, autoFocus, initialValue, validationFunc, onNextClick}) => {
    const [inputError, setInputError] = useState('');
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const hasError = inputError && inputError.length > 0;

    const handleOnNext = () => {
        const error = validationFunc(value);
        if (error && error.length > 0) {
            setInputError(error);
            return;
        }

        onNextClick && onNextClick(value);
        setInputError(null);
        setValue('');
    };

    return (
        <Stack direction="column" alignItems="center">
            <TextField autoFocus={autoFocus}
                       variant="standard"
                       size="small"
                       type='number'
                       value={value}
                       helperText={inputError}
                       sx={{
                           width: '40%',
                           '& input': {
                               textAlign: 'center',
                               fontSize: '24px'
                           }
                       }}
                       InputProps={{
                           endAdornment: <InputAdornment position="end"><Typography variant="h6"
                                                                                    color="primary">{suffix}</Typography></InputAdornment>
                       }}
                       error={hasError}
                       onChange={e => {
                           if (hasError && validationFunc) {
                               setInputError(validationFunc(e.target.value));
                           }

                           setValue(e.target.value)
                       }}
            />

            <NextButton availableNext={value && value.length > 0} onClick={handleOnNext}/>
        </Stack>
    );
};
