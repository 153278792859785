import {
    QuestionBmiIndex, QuestionDaysWithRepetition, QuestionEstimatedMinutes,
    QuestionExcludedCategories, QuestionExperience, QuestionIngredientExperiments,
    QuestionIngredientPreferences, QuestionMealExperiments,
    QuestionMealTypes, QuestionPersons
} from "../data/quiz";

export const mapAnswersToMenuPreferences = (answers) => {
    return {
        'ExcludedIngredientCategories': answers[QuestionExcludedCategories] || [],
        'IngredientsPreferences': {
            'MeatPreferences': answers[QuestionIngredientPreferences].filter(a => a.indexOf("meat_") !== -1).map(a => a.replace("meat_", "")) || [],
            'FishPreferences': answers[QuestionIngredientPreferences].filter(a => a.indexOf("fish_") !== -1).map(a => a.replace("fish_", "")) || [],
            'CerealPreferences': answers[QuestionIngredientPreferences].filter(a => a.indexOf("cereal_") !== -1).map(a => a.replace("cereal_", "")) || [],
            'SpicyPreferences': answers[QuestionIngredientPreferences].filter(a => a.indexOf("spicy_") !== -1).map(a => a.replace("spicy_", "")) || []
        },
        'NutrientPreferences': {
            'Kcal': Number(answers[QuestionBmiIndex]),
            'ProteinsPercentOfDv': 15,
            'FatsPercentOfDv': 30,
            'CarbsPercentOfDv': 55
        },
        'MealTypes': answers[QuestionMealTypes].map(a => ({'MealType': a})),
        'PersonsAdjustment': mapNumber(answers[QuestionPersons]),
        'EstimatedMinutesMax': mapEstimatedMinutes(answers[QuestionEstimatedMinutes]),
        'DifficultyMax': mapDifficulty(answers[QuestionExperience]),
        'MealExperimentsFrequency': mapExperimentsAns(answers[QuestionMealExperiments]),
        'IngredientsExperimentsFrequency': mapExperimentsAns(answers[QuestionIngredientExperiments]),
        'DaysWithRepetition': mapNumber(answers[QuestionDaysWithRepetition])
    };
};

const mapEstimatedMinutes = (ans) => {
    if (ans.includes('half_hour')) {
        return 30;
    }

    if (ans.includes('hour')) {
        return 60;
    }

    if (ans.includes('hour_and_half')) {
        return 120;
    }

    if (ans.includes('not_matter')) {
        return -1;
    }
};

const mapDifficulty = (ans) => {
    if (ans.includes('newbie')) {
        return 'Easy';
    }

    if (ans.includes('experienced')) {
        return 'Medium';
    }

    if (ans.includes('advanced')) {
        return 'Hard';
    }
};

const mapExperimentsAns = (ans) => {
    if (ans.includes('often')) {
        return 'Often';
    }

    if (ans.includes('sometimes')) {
        return 'Sometimes';
    }

    if (ans.includes('rarely')) {
        return 'Rarely';
    }
};

const mapNumber = (ans) => {
    if (ans.includes('one')) {
        return 1;
    }

    if (ans.includes('two')) {
        return 2;
    }

    if (ans.includes('three')) {
        return 3;
    }

    if (ans.includes('four')) {
        return 4;
    }

    if (ans.includes('five')) {
        return 5;
    }

    if (ans.includes('six')) {
        return 6;
    }
};