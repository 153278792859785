import React, {useEffect, useMemo} from "react";
import {bmiMax, bmiMin, calcCaloriesAndBmi, getBmiPhrase} from "../../../helpers/bmiCalc";
import Stack from "@mui/material/Stack";
import {Alert, AlertTitle, Box, Button, Paper, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {CreateCircle} from "../../../helpers/kcalCircle";
import {
    QuestionActivity,
    QuestionAge,
    QuestionGoal,
    QuestionHeight,
    QuestionSex,
    QuestionWeight
} from "../../../data/quiz";
import {useSelector} from "react-redux";

const BmiLine = ({bmi}) => {
    const cursorPosPercent = (bmi - bmiMin) / (bmiMax - bmiMin) * 100;

    return (
        <Stack direction="column" width="100%">
            <Box id="bmiLine"
                 component="div"
                 mt={3}
                 sx={{
                     position: 'relative',
                     height: '8px',
                     borderRadius: 2,
                     background: 'linear-gradient(90deg, #4098ff, #32dc80 29.17%, #ffde00 60.94%, #ff5252)',
                     width: '100%'
                 }}>

                <Typography variant="h6"
                            component="div"
                            sx={{
                                position: 'absolute',
                                transform: 'translate(-50%, calc(-100% - 8px));',
                                left: `${cursorPosPercent}%`
                            }}>
                    {bmi}
                </Typography>

                <Box id="bmiLine_cursor"
                     component="div"
                     sx={{
                         position: 'absolute',
                         top: 0,
                         bottom: 0,
                         width: '4px',
                         borderRadius: '2px',
                         outline: '3px solid #fff',
                         background: '#000',
                         left: `${cursorPosPercent}%`
                     }}/>
            </Box>

            <Grid2 container columns={3} mt={1}>
                <Grid2 xs>
                    Дефицит
                </Grid2>
                <Grid2 xs>
                    Норма
                </Grid2>
                <Grid2 xs>
                    Избыток
                </Grid2>
            </Grid2>
        </Stack>
    );
};

const BmiAlert = ({bmi}) => {
    const {
        title,
        description,
        type
    } = getBmiPhrase(bmi);

    return (
        <Box mt={2}>
            <Alert severity={type}
                   variant="outlined"
                   sx={{
                       textAlign: 'left'
                   }}>
                <AlertTitle>{title}</AlertTitle>
                {description}
            </Alert>
        </Box>
    );
};

const NutrientStat = ({color, name, quantity}) => (
    <Stack direction="row" spacing={1} alignItems="center">
        <Box component="div"
             sx={{
                 borderRadius: "100%",
                 height: "15px",
                 width: "15px",
                 background: color
             }}/>
        <Stack direction="column">
            <Typography variant="body2" fontWeight="bold" fontSize="14pt">{quantity}</Typography>
            <Typography variant="body2" fontSize="13pt" color="#97A2B0">{name}</Typography>
        </Stack>
    </Stack>
);

const DailyKcalValue = ({kcal, proteins, fats, carbs}) => {
    useEffect(() => {
        CreateCircle('kcalCircle')
    }, []);

    return (
        <Stack direction="column" mt={3} width="100%">
            <Typography variant="h5">
                Ваша суточная норма калорий
            </Typography>

            <Stack direction="row" mt={2} alignItems="center" spacing={2}>
                <Box id="kcalCircle"
                     component="svg"
                     viewBox="0 0 100 100"
                     width="100px"
                     height="100px"
                     mt={2}
                />

                <Typography variant="h5" fontWeight="bold">
                    {(+Math.round(kcal)).toLocaleString()} кКал*
                </Typography>
            </Stack>

            <Typography variant="body1" mt={2} textAlign="left" fontSize="14pt">
                Из которых
            </Typography>

            <Stack direction="row" spacing={5} mt={1}>
                <NutrientStat color='#F8E71C' name='Белки'
                              quantity={`${(Math.round(proteins * 100) / 100).toLocaleString()} гр`}/>
                <NutrientStat color='#BD10E0' name='Жиры'
                              quantity={`${(Math.round(fats * 100) / 100).toLocaleString()} гр`}/>
                <NutrientStat color='#FF0080' name='Углеводы'
                              quantity={`${(Math.round(carbs * 100) / 100).toLocaleString()} гр`}/>
            </Stack>

            <Typography variant="body2" mt={4} textAlign="left" fontSize="10pt" color="#97A2B0">
                * Данные результаты представлены в информационных целях, для более точных результатов обратитесь
                к специалистам.
            </Typography>
        </Stack>
    );
}

const NextButton = ({onClick}) => {
    return (
        <Paper elevation={1}
               sx={{
                   position: 'fixed',
                   bottom: 0,
                   zIndex: 999,
                   right: 0,
                   width: '100%'
               }}>
            <Button variant="contained"
                    color="secondary"
                    sx={{
                        color: 'white',
                        mb: 2,
                        mt: 2,
                        minWidth: '328px',
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={onClick}
                    size="large">
                Далее
            </Button>
        </Paper>
    );
};

export const BmiIndexQuestion = ({onNextClick}) => {
    const state = useSelector(state => state.quiz);
    const calcParams = {
        weight: Number(state.answers[QuestionWeight]),
        height: Number(state.answers[QuestionHeight]),
        age: Number(state.answers[QuestionAge]),
        sex: state.answers[QuestionSex],
        activityLevel: state.answers[QuestionActivity],
        goal: state.answers[QuestionGoal]
    };

    const {
        bmi,
        kcal,
        proteins,
        fats,
        carbs
    } = useMemo(() => calcCaloriesAndBmi(calcParams), []);

    const roundedBmi = Math.round(bmi * 100) / 100;
    return (
        <Stack direction="column"
               alignItems="center"
               ml={1}
               mr={1}
               mt={2}>
            <BmiLine bmi={roundedBmi}/>
            <BmiAlert bmi={roundedBmi}/>
            <DailyKcalValue kcal={kcal} proteins={proteins} fats={fats} carbs={carbs}/>
            <NextButton onClick={() => onNextClick(kcal)}/>
        </Stack>
    );
};