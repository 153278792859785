import {configureStore} from '@reduxjs/toolkit';
import featuresSlice from './featuresSlice.js';
import quizSlice from "./quizSlice";

const saveToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (e) {
        console.log(e);
    }
};

const loadFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("state");
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e);
    }
};

const store = configureStore({
    reducer: {
        features: featuresSlice,
        quiz: quizSlice
    },
    preloadedState: loadFromLocalStorage()
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;