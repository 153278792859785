import {
    Alert, AlertTitle,
    Avatar, Box,
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, Paper, TextField,
    Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Stack";
import {useMemo, useState} from "react";
import {AccountCircle, CheckCircleRounded, CircleOutlined, EmailRounded} from "@mui/icons-material";
import {createPaymentLink} from "../api/api";
import {useSelector} from "react-redux";
import {mapAnswersToMenuPreferences} from "../helpers/menuPreferences";
import {androidOptions} from "../data/paywall";
import {isAndroidOS} from "../helpers/isAndroid";
import {AbMetrikaId, sendDownloadApp, sendStartPayment} from "../helpers/analytics";
import {useFlag} from "yandex-metrica-ab-react";

const FREE_DOWNLOAD_CODE = "FREE_DOWNLOAD";
const FREE_DOWNLOAD_REDIRECT_LINK = "https://redirect.appmetrica.yandex.com/serve/1037742030173800407";

const Feature = ({icon, text, isLast}) => (
    <>
        <ListItem>
            <ListItemAvatar>
                <Avatar variant="square" src={icon}/>
            </ListItemAvatar>
            <ListItemText
                primary={<Typography fontSize="18px">{text}</Typography>}/>
        </ListItem>
        {!isLast && <Divider component="li"/>}
    </>
);

const FeaturesList = () => {
    const features = [
        {
            icon: "/assets/paywall/apple.png",
            text: "Поддерживайте здоровый образ жизни с готовым меню"
        },
        {
            icon: "/assets/paywall/hourglass.png",
            text: (<>Экономьте <b>время</b>: с меню вы всегда будете знать что приготовить</>)
        },
        {
            icon: "/assets/paywall/budget.png",
            text: (<>Экономьте <b>бюджет</b> на доставках из ресторанов и кафе, готовя дома вкусную и полезную еду</>)
        },
        {
            icon: "/assets/paywall/fear.png",
            text: "Забудьте про стресс при выборе блюд: меню подобрано под ваши вкусы и вам не придется перебирать сотни рецептов"
        },
        {
            icon: "/assets/shopping-list.png",
            text: "Не забывайте купить нужные продукты благодаря списку покупок к меню"
        },
        {
            icon: "/assets/paywall/food-waste.png",
            text: "Не выбрасывайте продукты: меню подобрано так, чтобы уменьшить пищевые отходы"
        }
    ];

    return (
        <List>
            {features
                .map((f, i, arr) => (
                    <Feature icon={f.icon} text={f.text} isLast={arr.length - 1 === i}/>
                ))}
        </List>
    );
};

const Benefit = ({text}) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar variant="square" src="/assets/paywall/checklist.png" sx={{width: '40px', height: '40px'}}/>
        </ListItemAvatar>
        <ListItemText
            primary={<Typography fontSize="18px">{text}</Typography>}/>
    </ListItem>
);

const BenefitsList = ({isAndroid}) => {
    const benefits = [
        {
            text: "Меню на каждый день с рецептами и подробными шагами приготовления",
            isAndroid: false
        },
        {
            text: "Список покупок к меню",
            isAndroid: false
        },
        {
            text: "Подбор альтернативных вариантов блюд для меню",
            isAndroid: true
        },
        {
            text: "Поиск блюд по ингредиентам, что есть у вас в холодильнике",
            isAndroid: true
        },
        {
            text: "Делитесь списком покупок с членами семьи",
            isAndroid: true
        },
        // {
        //     text: "Никаких автоматических списаний без вашего ведома",
        //     isAndroid: false
        // }
    ];

    return (
        <List>
            {benefits.map((b) => (!b.isAndroid || isAndroid) && (
                <Benefit text={b.text}/>
            ))}
        </List>
    );
};

const ButtonBuy = ({isLoading}) => {
    if (isLoading) {
        return (<div className="dots-flashing"/>);
    }

    return (
        <Button variant="contained"
                color="secondary"
                sx={{
                    color: 'white',
                    textTransform: 'none',
                    borderRadius: 4
                }}
                size="large"
                type="submit">
            Далее
        </Button>
    );
};

const BuyDialog = ({isOpen, onOpenChanged, offerCode}) => {
    const quizState = useSelector(s => s.quiz);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async ({name, email}) => {
        setIsLoading(true);
        const link = await createPaymentLink({
            menuPreferences: mapAnswersToMenuPreferences(quizState.answers),
            name,
            email,
            offerCode: offerCode
        });

        if (link === null) {
            setIsLoading(false);
            setHasError(true);
            return;
        }

        setHasError(false);
        if (offerCode === FREE_DOWNLOAD_CODE) {
            sendDownloadApp();
            window.location.href = FREE_DOWNLOAD_REDIRECT_LINK;
        } else {
            sendStartPayment();
            window.location.href = link.paymentLink;
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => onOpenChanged(false)}
            PaperProps={{
                component: 'form',
                sx: {
                    borderRadius: 5
                },
                onSubmit: async (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    const name = formJson.name;
                    await handleSubmit({name, email});
                },
            }}
        >
            <DialogTitle align="center">Получить меню</DialogTitle>
            <DialogContent>
                {hasError &&
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Упс... Что-то пошло не так!</AlertTitle>
                        Попробуйте повторить попытку еще раз.
                    </Alert>
                }
                <TextField
                    autoFocus={true}
                    required
                    margin="dense"
                    id="name2"
                    name="name"
                    type="text"
                    fullWidth
                    disabled={isLoading}
                    placeholder="Ваше имя *"
                    helperText="Нужно для обращения в письмах и приложении."
                    variant="outlined"
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                                <AccountCircle/>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    disabled={isLoading}
                    placeholder="Ваш email *"
                    type="email"
                    fullWidth
                    helperText="Этот Email будет использоваться для авторизации."
                    variant="outlined"
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                                <EmailRounded/>
                            </InputAdornment>
                        )
                    }}
                />

            </DialogContent>
            <DialogActions sx={{justifyContent: "center", mb: 1}}>
                <ButtonBuy isLoading={isLoading}/>
            </DialogActions>
        </Dialog>
    );
};

const MonthMenuBuyBlock = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <Paper elevation={3}
               sx={{
                   p: 3,
                   borderRadius: 3
               }}>
            <Typography variant="h5" fontWeight="bold">Получите ваше меню на месяц за</Typography>
            <Typography fontWeight="bold" fontSize="32px" color="secondary" mt={2}>299 руб.</Typography>

            <BuyDialog offerCode="MONTHPROMO299"
                       isOpen={openModal}
                       onOpenChanged={(val) => setOpenModal(val)}/>
            <Button variant="contained"
                    color="primary"
                    sx={{
                        color: 'white',
                        mt: 2,
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={() => setOpenModal(true)}
                    size="large"
                    fullWidth={true}>
                Получить меню
            </Button>
        </Paper>
    );
};

const AndroidAppButtonStartFor1Rub = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <Paper elevation={3}
               sx={{
                   p: 3,
                   borderRadius: 3
               }}>
            <Typography variant="h5" fontWeight="bold">Начните пользоваться за</Typography>
            <Typography fontWeight="bold" fontSize="32px" color="secondary" mt={2}>1 руб.</Typography>

            <BuyDialog offerCode="AB_APPWEEK1"
                       isOpen={openModal}
                       onOpenChanged={(val) => setOpenModal(val)}/>
            <Button variant="contained"
                    color="primary"
                    sx={{
                        color: 'white',
                        mt: 2,
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={() => setOpenModal(true)}
                    size="large"
                    fullWidth={true}>
                Начать
            </Button>
        </Paper>
    );
};

const AndroidDownloadApp = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <Paper elevation={3}
               sx={{
                   p: 3,
                   borderRadius: 3
               }}>
            <Typography variant="h5" fontWeight="bold">Завершите регистрацию и скачайте приложение, чтобы начать
                пользоваться</Typography>
            <BuyDialog offerCode={FREE_DOWNLOAD_CODE}
                       isOpen={openModal}
                       onOpenChanged={(val) => setOpenModal(val)}/>
            <Button variant="contained"
                    color="primary"
                    sx={{
                        color: 'white',
                        mt: 2,
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={() => setOpenModal(true)}
                    size="large"
                    fullWidth={true}>
                Завершить регистрацию
            </Button>
        </Paper>
    );
};


const AndroidAppBuyOption = ({
                                 checked,
                                 onChecked,
                                 optionName,
                                 benefitPercent,
                                 monthPrice,
                                 periodPrice,
                                 crossedPrice,
                                 isLast
                             }) => {
    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center"
                   onClick={() => onChecked()}>
                <Stack direction="row">
                    <Checkbox checked={checked}
                              checkedIcon={<CheckCircleRounded/>}
                              icon={<CircleOutlined/>}
                              color="secondary"
                              onChange={() => onChecked()}/>
                    <Stack direction="column" spacing="4px">
                        <Stack direction="row" alignItems="center">
                            <Typography variant="body1" fontSize="18px">
                                {optionName}
                            </Typography>

                            {benefitPercent && <Box ml="4px">
                                <Typography
                                    variant="body1"
                                    sx={{
                                        background: '#70b9be',
                                        color: 'white',
                                        borderRadius: 2,
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        padding: '2px'
                                    }}>
                                    {benefitPercent}
                                </Typography>
                            </Box>}
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            {crossedPrice && <Typography variant="body2"
                                                         sx={{
                                                             position: 'relative',
                                                             '&:after': {
                                                                 content: '""',
                                                                 width: '100%',
                                                                 height: '1px',
                                                                 backgroundColor: 'red',
                                                                 position: 'absolute',
                                                                 top: '50%',
                                                                 left: 0
                                                             }
                                                         }}>
                                {(+crossedPrice).toLocaleString()} ₽
                            </Typography>}

                            <Typography variant="body2">
                                {(+periodPrice).toLocaleString()} ₽
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Typography variant="body2" fontSize="18px">
                    {(+monthPrice).toLocaleString()} ₽ / мес.
                </Typography>
            </Stack>
            {!isLast && <Divider/>}
        </Stack>
    );
};

const AndroidAppBuyBlock = () => {
    const [checkedOption, setCheckedOption] = useState(androidOptions[0]);
    const [openModal, setOpenModal] = useState(false);

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="body1" fontSize="24px" fontWeight="bold">
                Выберите тарифный план и начните пользоваться приложением уже сегодня
            </Typography>

            <Paper elevation={3}
                   sx={{
                       p: 2,
                       borderRadius: 3
                   }}>
                <Stack direction="column" spacing={1}>
                    {androidOptions.map(((o, i, arr) => (
                        <AndroidAppBuyOption {...o}
                                             checked={checkedOption.offerCode === o.offerCode}
                                             onChecked={() => setCheckedOption(o)}
                                             isLast={arr.length - 1 === i}/>
                    )))}

                    <BuyDialog offerCode={checkedOption.offerCode}
                               isOpen={openModal}
                               onOpenChanged={(val) => setOpenModal(val)}/>

                    <Box mt={3}/>
                    <Button variant="contained"
                            color="primary"
                            sx={{
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: 4
                            }}
                            onClick={() => setOpenModal(true)}
                            size="large"
                            fullWidth={true}>
                        Перейти к оплате
                    </Button>
                </Stack>
            </Paper>
        </Stack>
    );
};

const BuyBlock = ({isAndroid}) => {
    if (isAndroid) {
        return (<AndroidDownloadApp/>);
        // return (<AndroidAppButtonStartFor1Rub/>);
        // return (<AndroidAppBuyBlock/>);
    }

    return (<MonthMenuBuyBlock/>);
};

const MoneyBackGuaranteeBlock = () => (
    <Stack direction="column" alignItems="center">
        <Avatar src="/assets/paywall/money.png" variant="square" sx={{width: '60px', height: '60px'}}/>

        <Typography variant="body1" fontWeight="bold" fontSize="18px">
            Гарантированный возврат средств
        </Typography>

        <Typography variant="body1" fontSize="16px">
            Мы надеемся, что составленные меню оправдают ваши ожидания, помогут достичь целей и упростят жизнь.
            Поэтому готовы вернуть средства, если этого не произойдет.
        </Typography>
    </Stack>
);

const ContactLinks = () => (
    <Grid2 container
           justifyContent="center"
           columnSpacing={1}
           rowSpacing={2}
           sx={{
               '& a': {
                   color: '#97A2B0',
                   textDecoration: 'none'
               }
           }}>
        <Grid2 xs={6}>
            <a href="https://cookhaus.guru/terms_of_use">Пользовательское соглашение</a>
        </Grid2>
        <Grid2 xs={6}>
            <a href="https://cookhaus.guru/privacy_policy">Политика конфиденциальности</a>
        </Grid2>
        <Grid2 xs={6}>
            <a href="mailto:support@cookhaus.guru">support@cookhaus.guru</a>
        </Grid2>
    </Grid2>
);

const PaywallPage = () => {
    const isAndroid = useMemo(() => isAndroidOS(), []);

    const headerPhrase = isAndroid ? 'Приложение настроено под вас' : 'Ваше персональное меню готово';
    return (
        <Stack direction="column" ml={1} mr={1}>
            <Item className="QuizHeader-brandLogo" href="https://cookhaus.guru" component="a" mt={2}>CookHaus</Item>

            <Box mt={3}
                 sx={{
                     background: '#70B9BE',
                     borderRadius: 4
                 }}>
                <Typography color="white"
                            fontSize="32px"
                            fontWeight="bold"
                            ml={1} mr={1} mt={3} mb={3}>
                    {headerPhrase}
                </Typography>
            </Box>

            <Box mt={3}/>

            <FeaturesList/>

            <Box mt={3}/>
            <Typography variant="h4" textAlign="left" fontWeight="bold">
                Вы получите
            </Typography>
            <BenefitsList isAndroid={isAndroid}/>

            <Box mt={2}/>
            <BuyBlock isAndroid={isAndroid}/>

            {
                !isAndroid &&
                <>
                    <Box mt={3}/>
                    <MoneyBackGuaranteeBlock/>
                </>
            }

            <Box mt={3}/>
            <ContactLinks/>

            <Box mt={6}/>
        </Stack>
    );
};

export default PaywallPage;