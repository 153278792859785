import './App.css';
import {Container, createTheme, ThemeProvider} from "@mui/material";
import {themeOptions} from "./themeOptions";
import FeatureSelectPage from "./pages/featureSelect";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import IntroPage from "./pages/intro";
import {AnimatePresence} from "framer-motion";
import QuizPage from "./pages/quiz";
import {useSelector} from "react-redux";
import LoadingPlan from "./pages/loadingPlan";
import PaywallPage from "./pages/paywall";
import {OverallQuestionsCount} from "./data/quiz";
import {useEffect} from "react";
import EndPaymentPage from "./pages/endPayment";
import RedirectPage from "./pages/redirect";

const theme = createTheme(themeOptions);
const router = createBrowserRouter([
    {
        path: "/intro",
        element: <IntroPage/>,
    },
    {
        path: "/",
        element: <FeatureSelectPage/>,
    },
    {
        path: "/quiz/:questionNum",
        element: <QuizPage/>
    },
    {
        path: "/loading-plan",
        element: <LoadingPlan/>,
    },
    {
        path: "/order-plan",
        element: <PaywallPage/>
    },
    {
        path: "/end-payment",
        element: <EndPaymentPage/>
    },
    {
        path: "/redir/:to",
        element: <RedirectPage/>
    }
]);

function App() {
    const quizState = useSelector(state => state.quiz);
    const redirectToLastVisitedPage = () => {
        const path = router.state.location.pathname;
        if (path === '/end-payment' || path.indexOf('/redir') > -1) {
            return;
        }

        const lastAnswerNum = Math.max(...Object.keys(quizState.answers));
        if (lastAnswerNum > 1 && lastAnswerNum < OverallQuestionsCount) {
            router.navigate('/quiz/' + (lastAnswerNum + 1));
            return;
        }

        if (lastAnswerNum >= OverallQuestionsCount) {
            router.navigate('/order-plan');
            return;
        }

        return;
    };

    useEffect(() => {
        redirectToLastVisitedPage();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container className="App" maxWidth="xs">
                <AnimatePresence>
                    <RouterProvider router={router}/>
                </AnimatePresence>
            </Container>
        </ThemeProvider>
    );
}

export default App;
