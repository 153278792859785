import axios from "axios";

export const [PaymentStatusPaid, PaymentStatusFailed] = ["Paid", "Failed"];

export const createPaymentLink = async ({menuPreferences, name, email, offerCode}) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/WebQuiz/CreatePaymentLink",
            {
                "SimpleSignInData": {
                    "Type": "Simple",
                    "Email": email,
                    "Password": "",
                    "SignUpData": {
                        "Name": name,
                    }
                },
                "MenuPreferences": menuPreferences,
                "OfferCode": offerCode
            }
        );
        if (response.status !== 200) {
            console.log('status code of creating payment link not 200');
            return null;
        }

        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const getPaymentStatus = async (paymentId, s) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/WebQuiz/GetPaymentStatus?paymentId=${paymentId}&s=${s}`);
        if (response.status !== 200) {
            console.log('status code of get payment status not 200');
            return null;
        }

        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
};